@use '../base';
@import '../variables';


.flag {
  cursor: pointer;
  width: 48px;
  height: 48px;
  margin: 10px;
  filter: grayscale(1);
}

.flag:hover {
  filter: grayscale(0.5);
}

.selected {
  filter: grayscale(0);
}

.selected:hover {
  filter: grayscale(0);
}