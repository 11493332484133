@use '../../common/base';
@use '../../common/variables';

.navbar-widget-container {
    display: flex;
    flex-direction: row;
}
#navbar-widget-icon-dropdown {
    display: flex;
    border-radius: 50%;
    border: none;
    height: 45px;
    width: 45px;
    position: relative;
    box-shadow: 0 0 2px 2px rgba($color: #e4e4e486, $alpha: 0.7);
    background-color: rgb(214, 214, 214);
    margin-right: 1rem;
}
// hide dropdown arrow
#navbar-widget-icon-dropdown::after {
    display: none;
}
#navbar-widget-icon-dropdown > svg {
    position: absolute;
    top: 11px;
    left: 13px;
}
.logout-item {
    font-size: 1.2rem;
    padding: 2px 10px;
    display: flex;
    justify-content: center ;
    align-items: center;
    // border-top: 1px solid rgba($color: #e4e4e486, $alpha: 0.7);
    min-width: 160px;
}
.navbar-widget-text-container {
    margin: 0 0.5rem;
    // border: 1px solid rgba($color: #e4e4e486, $alpha: 0.7);
    // border-radius: 10px;
    // margin-bottom: 1rem;
}
.navbar-widget-city-name {
    font-size: 1.2rem;
    font-weight: bold;
}
.navbar-widget-greeting {
    font-size: 1.8rem;
}
