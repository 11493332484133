@use '../common/base';
@use '../common/variables';

.log-page-search-bar-container {
    display: flex;
    justify-content: space-between;
}
.log-search-bar {
    margin: 2rem 0;
    display: flex;
    width: 85%;
}
.log-page-return-button {
    width: 10%;
    margin: 2rem 1rem;
    font-size: 2rem;
    text-align: center;
    vertical-align: middle;
}
.log-list-container {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    height: 90vh;
}
.log-table {
    height: 70vh;
    font-size: 1.5rem;
}
.log-table > thead > tr > th {
    position: sticky; 
    top: 0; 
    z-index: 1; 
}
.log-table-header {
    font-size: 2rem;
    font-weight: bold;
}
.log-table-content {
    margin-top: 10px;
    overflow-y: auto; 
    overflow-x: hidden;
    height: 60vh;
    display: block;
}
