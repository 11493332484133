@use '../../common/base';
@use '../../common/variables';

.view-brasao-text {
    font-size: 1rem;
    padding-top: 30px;
    height: 90px;
    width: 90px;
    border: 1px dashed gray;
    text-align: center;
}
.preview-brasao-img {
    max-width: 60px;
    max-height: 60px;
    display: flex;
    margin: auto;
    font-size: 1.6rem;
}