@use '../common/base';
@use '../common/variables';

.add-user-page-checkboxes-container {
    border: 1px solid rgb(221, 221, 221);
    margin: 0px;
    padding: 0 2px;
    border-radius: 3px;
}
.add-user-page-checkboxes-title {
    font-size: 1.5rem;
    margin: 1rem 18rem 1rem 0
}
.add-user-page-checkboxes-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.add-user-page-checkboxes-title-icon {
    margin: 0 1rem 0.3rem -0.2rem;
}