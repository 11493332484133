@use '../common/base';
@use '../common/variables';

.add-city-container {
    margin-top: 1%;
    max-width: 800px;
    display: flex;
    max-height: 85%;
}
.add-city-form-header {
    display: flex;
    margin-bottom: 2rem;
    border-bottom: 1px solid rgb(179, 179, 179);
    justify-content: space-between;
}
.btn-addCity-form {
    height: 3.5rem;
    font-size: 1.2rem;
    border-radius: 3px;
    margin-left: 3px;
}
.btn-addCity-form:disabled {
    height: 3.5rem;
    font-size: 1.2rem;
    border-radius: 3px;
    margin-left: 3px;
    background-color: gray;
    border-color: gray;
}
.add-city-form-row {
    display: flex;
    justify-content: space-between;
}
.add-city-cityName {
    width: 30%;
    height: 4rem;
    margin-right: 3px;
}
.add-city-cityUF {
    width: 12%;
    height: 4rem;
    margin-right: 3px;
}
.add-city-cityCoord {
    width: 20%;
    height: 4rem;
    margin-right: 3px;
}
.add-city-picoDU {
    width: 18%;
    height: 4rem;
    margin-right: 3px;
}
.add-city-picoFDS {
    width: 18%;
    height: 4rem;
    // margin-right: 3px;
}
.add-city-cityUF-label {
    font-size: 2rem;
    font-weight: bold;
}
.add-city-form-uploaders-grid {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    height: 70%;
    overflow-y: auto;
}