@use '../common/base';
@use '../common/variables';

.edit-container {
    margin-top: 5rem;
    max-width: 800px;
}
.form-header {
    display: inline;
    vertical-align: middle;
}
.form-header-text {
    float: left;
    font-size: 2.5rem;
    font-weight: bold;
    padding-top: -2px;
}
.input-group {
    height: 4rem;
}
.form-icon {
    font-size: 4rem;
}
.form-control {
    font-size: 1.5rem;
}
.buttons-div {
    float: right;
}
.btn-edit-form {
    font-size: 1.2rem;
    border-radius: 3px;
    margin-left: 1rem;
    margin-bottom: 1rem;
    height: 3rem;
    width: 5.5rem;
    text-align: center;
    vertical-align: middle;
    padding: auto;
}
.users-table-content {
    // margin-top: 10px;
    overflow-y: auto; 
    overflow-x: hidden;
    height: 60vh;
    display: block;
}
.edit-user-page-checkboxes-container {
    border: 1px solid rgb(221, 221, 221);
    margin: 0px;
    padding: 0 2px;
    border-radius: 3px;
}
.edit-user-page-checkboxes-title {
    font-size: 1.5rem;
    margin: 1rem 18rem 1rem 0
}
.edit-user-page-checkboxes-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.edit-user-page-checkboxes-title-icon {
    margin: 0 1rem 0.3rem -0.2rem;
}