@use '../common/base';
@import '../common/variables';

.indexPage-main-container {
    display: flex;
    height: 100%;
    flex-direction: column;
}
.indexPage-title {
    display: flex;
    margin: 4rem auto;
    font-size: 6rem;
}
.indexPage-sec-container {
    margin: 2rem auto;
    display: flex;
    width: 100%;
    height: 40%;
}

.indexPage-inner-container {
    display: flex;
    height: 100%;
    width: 80%;
    margin: auto;
    max-width: 1320px;
}
.indexPage-icons-boundingBox {
    display: flex;
    flex-direction: column;
    margin: auto;
}
.indexPage-main-icon {
    display: flex;
    margin: auto;
    padding: 5rem;
    border: 5px dashed gray;
    color: black;
}
.indexPage-main-icon:hover {
    display: flex;
    margin: auto;
    padding: 5rem;
    border: 5px solid rgb(78, 78, 78);
    color: white;
    background-color: gray;
}
.indexPage-incons-desc {
    margin: auto;
}