@use '../common/base';
@use '../common/variables';

#docspage-offcanvas {
    height: calc(100% - 60px);
    top: 60px
}

.docs-offcanvas-title {
    font-size: 2rem;
}

.my-viewer {
    position: absolute;
    width: calc(100% - 420px);
    height: calc(100% - 60px);
    left: 420px;
    object-fit: contain;
}

.offcanvas-body > .tree.jsx-2273069862 {
    transform: scale(1.3, 1.3);
    transform-origin: 0 0;
}

.docs-page-loader {
    margin-top: 20%;
    margin-left: 30%;
    height: 5rem;
}