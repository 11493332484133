@use '../../common/base';
@use '../../common/variables';

.iguPopupTitulo {
    text-align: center

}

.iguPopupTextoNome {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
}