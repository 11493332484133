@use '../../common/base';
@use '../../common/variables';


.igu-marker-popup-card-title {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    gap: 5px;
    padding: 5px;
}
.igu-marker-popup-line {
    margin-bottom: 0;
}
.igu-marker-popup-table {
    font-size: 1rem;
}
.nav-item > .tabText {
    font-size: .9rem;
    color: white;
    // border: 1px solid white;
    cursor: pointer;
}
.igu-marker-popup-card-header {
    color: white;
}
.doc-link {
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 3px;
}
.linha-bus-desc {
    margin-left: .5rem;
}