@use '../../common/base';
@use '../../common/variables';

.toolbar-nav {
    justify-content: center;
}
.igu-toolbar-container {
    background-color: rgba($color: #000000, $alpha: .8);
    border-radius: 3px;
    padding: .5rem 1rem;
    display: inline-block;
    justify-content: center;
}
.igu-toolbar-btn-tipo1 {
    font-size: 2rem;
    height: 4rem;
    width: 4rem;
}
.igu-toolbar-btn-tipo2 > .btn{
    font-size: 1.5rem;
    height: 4rem;
}
.igu-toolbar-group1, .igu-toolbar-group2, .igu-toolbar-group3 {
    margin: .5rem .5rem;
}
.toolbar-igu-icon {
    margin: 0 .5rem;
}
.igu-toolbar-btn-tipo2 > button > svg {
    margin-bottom: 3px;
}
.category-item {
    font-size: 1.5rem;
    padding: .3rem 1.5rem;
}
a.category-item > span.category-name {
    border-left: 1px solid black;
    padding-left: 1.1rem;
}
a.category-item > svg {
    margin-bottom: 3px;
    margin-right: 12px;
}
.tooltip {
    top: 0;
    left: 0;
    font-size: 1.5rem;
}
span.category-qtd {
    color: white;
    font-size: 1.2rem;
    margin-left: .8rem;
    background-color: gray;
    border-radius: 50%;
    padding: 4px;

}