@use '../../common/base';
@import '../../common/variables';

.myNav {
    // background-color: $background-color;
    border-bottom: 1px groove $background-color;
    height: 6rem;
}
.myNav-loginPage {
    visibility: hidden;
}
// .logo-navbar {
//     width: 15rem;
//     margin-right: 8rem;
// }
.myNav-item {
    display: flex;
    font-size: 2.5rem;
    margin-right: 4rem;
}
.navbar-light .navbar-nav .nav-link.active {
    background-color: $nav-button-background-color;
    margin: -3px 0 -3px 0;
    padding-left: 7px;
    padding-right: 7px;
    color: white;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba($color: #e4e4e486, $alpha: 0.7);
    // padding: 10px;
}
.nav-item-inner {
    display: flex;
    justify-content: center;
}
.navbar-icon {
    margin-top: 7px;
    margin-right: 3px;
}
.logout-icon {
    width: 3rem;
    padding: 0;
    font-size: 2.5rem;
    vertical-align: middle;
    margin: 2px 0 0 2px;
}
.logout-button {
    height: 4.3rem;
    width: 4.3rem;
    border-radius: 50%;
    box-shadow: 0 0 2px 2px rgba($color: #ffc9c941, $alpha: 0.7)
}
