@use '../../common/base';
@use '../../common/variables';

.search-modal {
    max-width: 1500px;
    height: 95%;
    overflow-y: scroll;
}
.search-modal > .modal-content {
    overflow-y: scroll;
}
.search-modal-table-header {
    font-size: 1.5rem;
    text-align: center;
    vertical-align: middle;
}
.search-modal-table-content {
    font-size: 1.2rem;
    text-align: center;
    font-weight: normal;
    vertical-align: middle;
    cursor: pointer;
}
.search-modal-table-align-left {
    text-align: left;
    // margin-left: 1rem;
}
.search-modal-header-icon {
    margin-left: .3rem;
    margin-right: 1rem;
    font-size: 2.8rem;
}
.search-modal-int-icons > .interseccao-icon {
    margin: auto;
}
.search-modal-int-icons  {
    vertical-align: middle;
}
.searchModal-card-header {
    color: white;
    padding-bottom: 0;
    margin-bottom: -1.1rem;
    background-color: #2b692d;
}
.searchModal-nav-item > .searchModal-tabText {
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
}
.searchModal-nav-tab {
    margin-bottom: 0.02rem;
}
.igu-searchModal-input {
    margin-bottom: 1rem;
}
.searchModal-card-header > .btn-close {
    color: black !important;
}
.search-modal-table-header-names:nth-child(2) {
    text-align: left;
}