@use '../../common/base';
@use '../../common/variables';

.interseccao-icon {
    height: 35px;
    width: 35px;
    display: flex;
    flex-direction: column;
    position: relative;
    top: -17px
}
.interseccao-corredor {
    background-color: orange;
    box-shadow: 0 0 2px 2px rgba($color: orange, $alpha: 0.7);
}
.interseccao-adjacente {
    background-color: green;
    box-shadow: 0 0 2px 2px rgba($color: green, $alpha: 0.7);
}
.interseccao-nova {
    background-color: cornflowerblue;
    box-shadow: 0 0 2px 2px rgba($color: cornflowerblue, $alpha: 0.7);
}
.interseccao-existente {
    background-color: brown;
    box-shadow: 0 0 2px 2px rgba($color: brown, $alpha: 0.7);
}
.interseccao-adversa {
    background-color: grey;
    box-shadow: 0 0 2px 2px rgba($color: grey, $alpha: 0.7);
}
.interseccao-Semaforizada {
    background-color: green;
    box-shadow: 0 0 2px 2px rgba($color: green, $alpha: 0.7);
}
.interseccao-conjugado {
    background-color: rgb(134, 196, 131);
    box-shadow: 0 0 2px 2px rgba($color: rgb(83, 145, 83), $alpha: 0.7);
}
.interseccao-principal {
    background-color: green;
    box-shadow: 0 0 2px 2px rgba($color: green, $alpha: 0.7);
}
.interseccao-esconder {
    visibility: hidden;
}
.interseccao-icon > div {
    display: flex;
    color: white;
    margin: 1px auto;
    font-weight: bold;
}
.interseccao-icon > img {
    display: flex;
    margin: -2px auto
}