@use '../../common/base';
@use '../../common/variables';

.city-widget-outter-container {
    width: 80%;
    height: 90%;
    position: relative;
}
.city-widget-content {
    border: 2px dashed gray;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.city-widget-content:hover {
    background-color: gray;
    border: 2px solid black;
    cursor: pointer;
}
.btn-city-widget {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 3px;
    text-decoration: none;
    text-align: center;
    position: absolute;
}
.btn-medicoes-city-widget {
    right: -29px;
}
.btn-relatorio-city-widget {
    right: -29px;
    top: 27px;
}
.btn-dashboard-city-widget {
    top: 54px;
    right: -29px;
}
.btn-edit-city-widget {
    top: 81px;
    right: -29px;
}
.btn-delete-city-widget {
    right: -29px;
    top: 108px;
}
.btn-city-widget-relatorio-icon, .btn-city-widget-medicoes-icon {
    transform: scale(1.5);
}
.btn-city-widget-edit-icon, .btn-city-widget-delete-icon, .btn-city-widget-dashboard-icon {
    transform: scale(1.8);
}
.city-widget-brasao {
    margin: auto;
}
.cities-page-city-widget-cityName {
    color: rgb(51, 51, 51);
}