@use '../base';
@import '../variables';

.loader-div {
    background-color: $background-color;
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: center;
}
.loader {
    background-color: $background-color;
    margin: auto;
    height: 5rem;
}