@use '../base';
@use '../variables';

.my-checkbox-container {
    // margin: 2rem;
    display: flex;
    align-items: center;
}
.my-checkbox-check {
    margin-right: .5rem;
}
