@use '../../common/base';
@import '../../common/variables';

.od-controls {
    background-color: rgba($color: #ffffff, $alpha: .8);
    border-radius: 3px;
}
.od-controls-expand-button {
    font-size: 1.5rem;
}
#od-controles-offcanvas {
    top: 60px;
    height: calc(100% - 60px);
    background-color: rgba($color: #ffffff, $alpha: .9);
}
.od-controles-offcanvas-title {
    display: flex;
    font-size: 2rem;
    flex-direction: row;
    align-items: center;
}
.od-controles-title-text {
    display: flex;
}
.od-tipo-dropdown > button {
    font-size: 2rem;
    color: gray;
}
.od-controles-offcanvas-title > svg {
    transform: scale(1.1, 1.1);
    display: flex;
    margin-right: 1rem;
}
.od-controles-acc-item {
    position: relative;
}
.od-controles-acc-header > button {
    font-size: 1.8rem;
}
.od-controles-acc-header > button > svg { 
    transform: scale(.95, .95);
    margin-right: 1rem;
}
.od-controls-acc-select-all-button {
    position: absolute;
    top: 5.5px;
    right: 95px;
    z-index: 100;
}
.od-controls-acc-limpar-all-button {
    position: absolute;
    top: 5.5px;
    right: 40px;
    z-index: 100;
    background-color: gray;
    color: white;
} 
.zonasLabels-invisible {
    display: none;
}
.zonasLabels{
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0.4);
    border:0;
    border-radius:5px;
    box-shadow: 0 0px 0px;
    z-index: 100;
}

/* itens do offcanvas */
.accordion-button {
    padding: .70rem .96rem;
}

/* campos de data e hora */
.form-control {
    padding: .375rem .4rem;
}

/* botao dia-tipo e hora-pico */
.btn-dia-tipo {
    box-sizing: border-box;
    max-width: 42px;
    min-width: 42px;
    padding: 6px 6px;
    border-color: rgba(128, 128, 128, 0.5);
    color: $btn-color;
    background-color: rgba(255, 255, 255, 0.8);
}
.btn-hora-pico {
    box-sizing: border-box;
    padding: 6px 6px;
    max-width: 42px;
    min-width: 42px;
    border-color: rgba(128, 128, 128, 0.5);
    color: $btn-color;
    background-color: rgba(255, 255, 255, 0.8);
}

/* grupo botao data ou hora */
.input-group {
    margin-bottom: .5rem;
}
/* botao de zonas */

.btn-zonas {
    color: $btn-color;
    display: block;
    border: 1.8px solid $btn-color;
    text-align: center;
    width: 100%;
    font-size: 15px;
    margin: 3px auto;
    border-radius: 5px;
}
.botao-zonas-container {
    display: flex;
    padding: 0 5px; 
}
.btn-zonas:hover, .btn-zonas:active{
    font-weight: bold;
    border: 2px solid $btn-color;
}

/* botao de tipo de pagamento */
.btn-pagamentos{
    display: block;
    border: 1px 0px solid $btn-color;
    color: $btn-color;
    text-align: left;
    margin: 2px 0px;
    padding: 0px 5px;
    width: 100%;
}
.btn-pagamentos:hover {
    font-weight: bold;
}
.btn-pagamentos:active {
    color: white;
}
.btn-check:checked + .btn-outline-primary {
    background-color: $btn-color;
    font-weight: bold;
    color: white;
}
.btn-zona-selected{
    background-color: $btn-color;
    font-weight: bold;
    color: white;
}
.btn-tipo-selected{
    background-color: $btn-color;
    font-weight: bold;
    color: white;
    border-radius: 2px;
}
.fluxos-table {
    overflow: auto;
    text-align: center;
    /* border-collapse: separate; */
}
.limitar-tabela {
    max-height: 500px;
    overflow: auto;
}
thead {
    position: sticky;
    top: 0;
    background-color: white;
    box-shadow: inset 0 1px 0 black, inset 0 -1px 0 black;
}
/* dimensao offcanvas */
.offcanvas {
    width: 100%;
    max-width: 420px;
}

/* botao calendario */
.icone-calendario {
    margin-right: 0;
}
/* fim offcanvas */