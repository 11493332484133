@use '../common/base';
@import '../common/variables';

.city-list-container{
    margin-top: 5rem;
    max-width: 800px;
}

// barra de busca ---------------------------------------------------------------
.cities-search-input {
    font-size: 1rem;
    height: 5rem;
}
#search-icon-container {
    width: 50px;
    height: 5rem;
    padding: 8px;
    font-weight: bold;
}
#btn-search-icon {
    font-size: 2rem;
    margin-left: -2px;
    color: green;
}
#add-city-group {
    height: 50px;
    text-decoration: none;
    width: 115px;
}
.add-city-icon {
    display: flex;
    font-size: 30px;
    color: green;
    float: left;
}
.add-city-label {
    font-size: 18px;
    margin-left: 8px;
    text-decoration: none;
    vertical-align: middle;
}
#add-city-group:hover {
    color: green;
}
.cities-page-back-button {
    margin-left: 1rem;
    font-size: 1.5rem;
}
// lista de cidades -------------------------------------------------------------
.cities-page-selectCity-container {
    justify-content: flex-start;
}
.cities-page-city-widget-cityName {
    font-size: 1.5rem;
    font-weight: bold;
}
.cities-page-brasao-content {
    border: 1px solid black;
    width: 80%;
    aspect-ratio: 1/1;
}
.select-city-widget {
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    color: $body-font-color;
    margin-bottom: 1rem;
    text-align: center;
    height: 15rem;
}
.cities-page-buttons-row {
    display: flex;
    justify-content: space-evenly;
}
.btn-city{
    width: 5rem;
    height: 30px;
    font-size: 2rem;
    border-radius: 3px;
    text-decoration: none;
    text-align: center;
    position: relative;
    margin-top: -3rem;
}
.btn-delete-edit {
    margin-right: -2rem;
}
.btn-city-delete-icon, .btn-city-edit-icon {
    position: absolute;
    top: 3.5px;
    right: 2.3rem;
    display: flex;
}