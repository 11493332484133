@use '../../common/base';
@use '../../common/variables';

.select-json-button {
    width: 200px;
    height: 120px;
    position: relative;
}
.select-json-text {
    margin: 0 auto;
    font-size: 1.3rem;
}
.select-json-plus-icon {
    position: absolute;
    top: 30px;
    right: 70px;
}
.preview-json {
    display: flex;
    width: 200px;
    height: 120px;
    border: 3px solid gray;
    pointer-events: all;
    border-radius: 3px;
    position: relative;
    text-decoration: none;
    flex-direction: column;
}
.preview-json-file-name {
    // max-width: 250px;
    max-height: 220px;
    padding-top: 10px;
    font-style: italic;
    color: gray;
    overflow: hidden;
    font-size: 1rem;
}
.uploaded-json-file-icon {
    display: flex;
    flex-direction: column;
    margin: auto;
}
.json-file-icon-name-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
}
.preview-json-file:hover {
    max-width: 250px;
    max-height: 220px;
    display: flex;
    margin: auto;
}
.json-edit-icon {
    position: absolute;
    right: 30px;
    top: 3px;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 2px;
    background-color: gray;
    color: white;
}
.json-delete-icon-ro {
    position: absolute;
    right: 3px;
    top: 3px;
    color: gray;
}
.json-delete-icon {
    position: absolute;
    color: gray;
    right: 0;
    top: 0;
    opacity: 0;
    z-index: 2;
}
.json-delete-icon:hover {
    position: absolute;
    color: red;
    z-index: 2;
    right: 3px;
    top: -1px;
    opacity: 1;
}
.preview-json-overlay {
    position: absolute;
    left: -3px;
    top: -3px;
    width: 200px;
    height: 120px;
    z-index: 1;
    opacity: 0;
}
.preview-json-overlay:hover {
    position: absolute;
    left: -3px;
    top: -3px;
    width: 200px;
    height: 120px;
    z-index: 1;
    background-color: rgba($color: white, $alpha: .7);
    opacity: 1;
}
.preview-json-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 200px;
}
.json-file-loaded-icon {
    position: absolute;
    top: 0px;
    right: -22px;
}
.json-file-load-status-icon {
    position: absolute;
    top: 0px;
    right: -22px;
}
.ok-status {
    color: green;
}
.json-file-loaded-info-icon {
    position: absolute;
    bottom: 16px;
    right: -22px;
    color: gray;
}
.uploader-widget {
    display: flex;
    margin: auto;
    justify-content: center;
    padding-bottom: 20px;
}