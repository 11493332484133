@use '../common/base';
@import '../common/variables';

.outter-div {
    display: flex;
    margin-top: 20rem;
    height: 75%;
}
.flex-child {
    flex: 1;
    height: 50rem
}
// .left-box {
//     border-right: 1px solid rgba(0, 0, 0, 0.342);
// }
.left-outter-box {
    display: flex;
    margin: 2rem 42%;
    flex-direction: column;
    width: 100%;
}
.phrase-login-page {
    width: 100%;
    margin: 0 5px;
}
.login-box { 
    display: flex;
    height: 32rem;
    width: 28rem;
    position: relative;
    margin-left: 22%;
    margin-top: 9%;
    background-color: rgb(245, 245, 245);
    justify-content: center;
    border-radius: .5rem;
    box-shadow: 0 0 3px 3px rgba($color: #e4e4e486, $alpha: 0.7);
    flex-direction: column;
}
.person-icon-login {
    display: flex;
    font-size: 8rem;
    color: $dark-blue;
    padding: 0;
    margin: 4rem auto 0 auto;
}

.welcome-text {
    font-size: 2.5rem;
    margin: 1rem auto;
    color: $dark-blue;
}
.login-form {
    display: flex;
    flex-direction: column;
    margin: 1rem 2rem 1rem 2rem;
}
.form-input {
    height: 4rem;
    font-size: medium;
    text-align: center;
}
.login-button {
    height: 4rem;
    margin: 0 3rem -4rem 3rem;
    font-size: 2rem;
    background-color: $igu-green-color;
    border-color: $igu-green-color;
}
.login-button:hover {
    background-color:rgba($color: $igu-green-color, $alpha: 0.7);
    border-color:rgba($color: $igu-green-color, $alpha: 0.7)
}
.footer {
    position: relative;
    vertical-align: middle;
    text-align: center;
    font-size: small;
    color: white;
    background-color: rgba(48, 48, 48, 0.815);
    height: 10rem;
}
.footer-text {
    position: absolute;
    margin: 25px 40%;
}