@use '../common/base';
@use '../common/variables';

.igu-progress-dashboard-item-container {
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 3rem;
    justify-content: space-between;
    color: white;
    margin-bottom: 2rem;
}
.igu-progress-dashboard-item-left {
    display: flex;
    align-items: center;
}
.igu-progress-dashboard-item-icon {
    display: flex;
    margin: 0 1rem;
}
.igu-progress-dashboard-item-text {
    display: flex;
}
.igu-progress-dashboard-item-qtd {
    display: flex;
    border-left: 2px solid white;
    padding: 1rem;
}
.dashboard-item-green {
    border: 5px solid green;
    background-color: green;
}
.dashboard-item-brown {
    border: 5px solid brown;
    background-color: brown;
}
.dashboard-item-blue {
    border: 5px solid blue;
    background-color: rgb(0, 0, 255);
}
.dashboard-item-gray {
    border: 5px solid gray;
    background-color: rgb(128, 128, 128);
}
.dashboard-item-orange {
    border: 5px solid orange;
    background-color: rgb(255, 166, 0);
}
.dashboard-item-purple {
    border: 5px solid purple;
    background-color: rgb(128, 0, 128);
}
.dashboard-item-pink {
    border: 5px solid pink;
    background-color: rgb(255, 192, 203);
}
.dashboard-item-black {
    border: 5px solid black;
    background-color: rgb(0, 0, 0);
}
.dashboard-item-yellow {
    border: 5px solid rgb(255, 217, 0);
    background-color: rgb(255, 217, 0);
}
.dashboard-item-cyan {
    border: 5px solid cyan;
    background-color: cyan;
}
.dashboard-item-lightgreen {
    border: 5px solid lightgreen;
    background-color: lightgreen;
}