@use '../common/base';
@import '../common/variables';

.user-list-container{
    margin-top: 5rem;
    max-width: 1000px;
}

// barra de busca ---------------------------------------------------------------
.users-page-back-button {
    margin-left: 2.5rem;
    font-size: 2rem;
}
.users-search-input {
    font-size: 1rem;
    height: 5rem;
}
#search-icon-container {
    width: 50px;
    height: 5rem;
    padding: 8px;
    font-weight: bold;
}
#btn-search-icon {
    font-size: 2rem;
    margin-left: -2px;
    color: green;
}
#add-user-group {
    height: 50px;
    text-decoration: none;
    padding-left: 6px;
    margin-left: 10px;
    width: 115px;
}
.add-user-icon {
    display: flex;
    font-size: 30px;
    color: green;
    float: left;
}
.add-user-label {
    font-size: 18px;
    margin-left: 8px;
    text-decoration: none;
    vertical-align: middle;
}
#add-user-group:hover {
    color: green;
}
// lista de usuarios -------------------------------------------------------------
.users-header {
    font-size: 1.8rem;
    font-weight: bold;
}
.last-header {
    text-align: right;
}
.user {
    font-size: 2rem;
    color: $body-font-color
}
.btn-user {
    width: 35px;
    height: 30px;
    margin-right: 1rem;
    font-size: 2rem;
    border-radius: 3px;
    text-decoration: none;
    vertical-align: middle;
    position: relative;
}
.btn-delete-edit {
    margin-right: -2rem;
}
.btn-user-delete-icon, .btn-user-edit-icon {
    position: absolute;
    top: 3.5px;
    right: 6.5px;
    display: flex;
}
.btn-row {
    text-align: right;
}