@import './variables';

@font-face {
    font-family: 'Roboto Light';
    src: url('./fonts/Roboto-Light.ttf') format('truetype');
}

html {
    font-size: 62.5%;
    background-color: $background-color;
}

body {
    font-family: 'Roboto Light', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    height: 100vh;
    font-size: 2rem;
    color: $body-font-color;
}

#root {
    width: 100%;
    height: 100%;
}

.App {
    position: absolute;
    width: 100%;
    height: 100%;
}
