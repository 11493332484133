@use '../../common/base';
@use '../../common/variables';

#map {
    height: calc(100% - 60px)
}
.myMap-intLegend, .myMap-busLegend  {
    background-color: rgba($color: white, $alpha: .9);
    border-radius: 5px;
    padding: 1rem;
    font-size: 1.4rem;
}
.leaflet-bottom > .myMap-intLegend {
    margin-bottom: 70px;
}
.myMap-busLegend {
    overflow-y: auto;
    max-height: calc(100vh - 130px);
    min-width: 200px;
    max-width: 250px;
}
.myMap-intLegend-title, .myMap-busLegend-title{
    color: black;
    border-bottom: 1px solid rgba(128, 128, 128, 0.699);
    margin-bottom: 4px;
    font-size: 1.6rem;
}
.linha-bus-color {
    width: 15px;
    height: 15px;
    margin-top: 3px;
    margin-right: 3px;
}
.linha-bus-checkbox {
    display: flex;
    margin-right: 1rem;
}
.my-buslist-checkbox {
    margin-right: .5rem;
}
.invisible-linhaBus {
    display: none;
}
.checkall-checkbox {
    margin-right: 4px;
}
.leaflet-popup {
    margin-bottom: 35px;
}