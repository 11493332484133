@use '../common/variables';
@use '../common/base';

.igu-dashboard-outter-container {
    max-width: 800px;
    height: 90%;
}
.igu-dashboard-title {
    display: flex;
    flex-direction: row;
    margin: 2rem 0;
    font-size: 5rem;
    color: black;
    font-weight: bold;
    justify-content: space-between;
}
.igu-dashboard-title-left {
    display: flex;
}
.igu-dashboard-title-icon {
    display: flex;
    align-items: center;
    margin-right: 1rem;
}
.igu-dashboard-title-text {
    display: flex;
}
.igu-dashboard-container {
    margin-top: 3rem;
    display: flex;
}
.igu-dashboard-return-button {
    font-size: 2rem;
    margin-top: 3rem;
    height: 4rem;
    aspect-ratio: 4/3;
    float: right;
}