@use '../../common/base';
@use '../../common/variables';

.igu-background-img-div {
    background-color: grey;
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: center;
}
.igu-background-img {
    background-color: grey;
    margin: auto;
}