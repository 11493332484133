@use '../common/base';
@use '../common/variables';

.edit-city-container {
    margin-top: 1%;
    max-width: 800px;
    display: flex;
    max-height: 89%;
}
.edit-city-form {
    width: 100%;
}
// header -----------------------------------------------------------------
.edit-form-header {
    display: inline;
    vertical-align: middle;
    width: 100%;
    font-size: 2rem;
    margin-bottom: 2rem;
}
.edit-city-header-buttons-div {
    float: right;
}
.btn-editCity-form {
    height: 3.5rem;
    font-size: 1.2rem;
    border-radius: 3px;
    margin-left: 5px;
    width: 6rem;
}
.btn-editCity-form:disabled {
    height: 3.5rem;
    font-size: 1.2rem;
    border-radius: 3px;
    margin-left: 5px;
    width: 6rem;
    background-color: gray;
    border-color: gray;
}
// first row (Nome cidade, sigla, brasao) ---------------------------------
.edit-city-form-first-row {
    display: flex;
    justify-content: space-between;
}
.edit-city-name-and-brasao {
    display: flex;;
}
#edit-city-cityName {
    font-size: 50px;
    line-height: normal;
    margin-top: 1rem;
}
.brasao-viewer-widget {
    margin: 0 2rem;
    display: flex;
    height: 90px;
    width: 90px;
    border: 1px dashed gray;
}
.edit-city-form-g1 {
    display: flex;
}
.edit-city-form-g2 {
    display: flex;
}
.edit-city-form-g1-g2 {
    display: flex;
    flex-direction: column;
    max-width: 35%;
}
.edit-city-cityUF {
    width: 12rem;
    margin-right: 1rem;
}
.edit-city-picoDU {
    margin-right: 1rem;
}
.edit-city-form-label {
    font-size: 1.7rem;
}
// > 2nd row ---------------------------------------------------------------
.edit-city-form-uploaders-grid {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    height: 70%;
    overflow-y: auto;
}
